import React from "react"
import { Box } from "@mui/material"
import NavbarItem from "./navbar-item"
import * as navStyles from "../../styles/nav.module.css"

const NavbarPortal = () => {
  return (
    <Box id={navStyles.container}>
      {/* <NavbarItem
        title="my.torontomu"
        portal
        link="https://my.torontomu.ca/uPortal/f/u21l1s1000/normal/render.uP"
      /> */}
      <NavbarItem
        title="D2L Brightspace"
        portal
        link="https://courses.torontomu.ca/d2l/home"
      />
      <NavbarItem
        title="MyServiceHub"
        portal
        link="https://my.torontomu.ca/uPortal/f/myservicehub/normal/render.uP"
      />
      <NavbarItem
        title="Account Settings"
        portal
        link="https://my.torontomu.ca/uPortal/p/rmsSelfServe.ctf2/max/render.uP?pP_section=personal"
      />
    </Box>
  )
}

export default NavbarPortal
